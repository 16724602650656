import React from "react"
import "./404.module.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from "gatsby-link"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found" />
    <div styleName="wrapper">
      <section styleName="error-container">
        <span styleName="four">
          <span styleName="screen-reader-text">4</span>
        </span>
        <span styleName="zero">
          <span styleName="screen-reader-text">0</span>
        </span>
        <span styleName="four">
          <span styleName="screen-reader-text">4</span>
        </span>
      </section>
      <h5 className="text-center">
        The page you are looking for does not exist.
      </h5>
      <div styleName="link-container">
        <Link to="/">Go to home</Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
